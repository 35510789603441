type Color = { base: string; lt?: string; dk?: string; hlt?: string }

type Colors = { [key: string]: Color }

const colors: Colors = {
  main: {
    base: "rgb(130, 0, 36)",
    lt: "rgb(130, 0, 36)",
    dk: "rgb(68, 0, 0)",
  },
  link: {
    base: "rgb(207, 194, 21)",
    hlt: "rgb(230, 193, 11)",
  },
  grey: {
    base: "rgb(87, 65, 65)",
    lt: "rgb(138, 104, 90)",
    dk: "rgb(66, 52, 52)",
  },
  success: {
    base: "rgb(0, 122, 10)",
  },
  error: {
    base: "rgb(153, 17, 8)",
  },
}

const color = (color: string, variation?: string, opacity?: number) => {
  let calcedColor
  if (!variation) {
    if (!colors[color].base) {
      calcedColor = colors[color]
    }
    calcedColor = colors[color].base
  } else {
    calcedColor = colors[color][variation as keyof Color]
  }
  if (opacity) {
    calcedColor = calcedColor
      .replace("rgb", "rgba")
      .replace(")", `, ${opacity})`)
  }
  return calcedColor
}

export default color
